import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { PromosAdmin } from "../pages/BarAdmin/Tabs/PromosAdmin";
import { ReviewsAdmin } from "../pages/BarAdmin/Tabs/ReviewsAdmin";
import { BarConfigAdmin } from "../pages/BarAdmin/Tabs/BarConfigAdmin";
import { BaseAPI } from "../apis/BaseAPI";
import { CatConfig } from "../pages/BarAdmin/Tabs/CatConfig";
import { AdmBarConfig } from "../pages/BarAdmin/Tabs/AdmBarConfig";

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const TabsPanelDiv = styled.div`
  display: flex;
  margin-inline: auto;
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <TabsPanelDiv
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ display: value !== index ? "none" : "" }}
      {...other}
    >
      {value === index && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </TabsPanelDiv>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function PageDisplayWithTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        <Tab label="Promoções" {...a11yProps(0)} />
        <Tab label="Configurações do bar" {...a11yProps(1)} />
        <Tab label="Reviews" {...a11yProps(2)} />
        {BaseAPI.getUser().user.manager_account ? (
          <Tab label="Cat Configs" {...a11yProps(3)} />
        ) : null}
        {BaseAPI.getUser().user.manager_account ? (
          <Tab label="Bar Configs" {...a11yProps(4)} />
        ) : null}
      </Tabs>
      <TabPanel value={value} index={0}>
        <PromosAdmin />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BarConfigAdmin />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ReviewsAdmin />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <CatConfig />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <AdmBarConfig />
      </TabPanel>
    </Container>
  );
}
