import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { StyledH3 } from "../atoms/StyledTexts";
import styled from "styled-components";
import { useAsync } from "../hooks/useAsync";
import { useState } from "react";
import { BarInfoAPI, ReviewBarQuery } from "../apis/BarApi";
import { useIsMounted } from "../hooks/useIsMounted";
import { OpenInNew } from "@mui/icons-material";
import { asyncCallback } from "../utils";
import { BaseAPI } from "../apis/BaseAPI";
import { CategoryAPI, FetchTags } from "../apis/CategoryApi";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-width: 70vw;
`;

const InputGrid = styled.div`
  display: grid;
  gap: 20px;
  align-items: center;
  grid-template-columns: auto auto;
`;

const GridItemWithLink = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const StyledImg = styled.img`
  border: 5px solid white;
  border-radius: 5px;
  -webkit-box-shadow: 7px 6px 16px 0px rgba(179, 168, 179, 0.6);
  -moz-box-shadow: 7px 6px 16px 0px rgba(179, 168, 179, 0.6);
  box-shadow: 7px 6px 16px 0px rgba(179, 168, 179, 0.6);
`;

export function BarConfig(): JSX.Element {
  const [resultsAreLoading, setResultsAreLoading] = useState<boolean>(true);
  const [barAddress, setBarAddress] = useState<string>("");
  const [barInsta, setBarInsta] = useState<string>("");
  const [barPhone, setBarPhone] = useState<string>("");
  const [barProfilePic, setBarProfilePic] = useState<string>("");
  const [barMenu, setBarMenu] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [tags, setTags] = useState<FetchTags>([]);

  const isMounted = useIsMounted();

  useAsync(
    isMounted,
    async () => {
      return Promise.all([
        BarInfoAPI.fetchBarInfo(BaseAPI.getSelectedBar().id),
        CategoryAPI.fetchTags(),
      ]);
    },
    ([data, tagsData]) => {
      setBarAddress(data.bar_adress);
      setBarPhone(data.bar_phone);
      setBarProfilePic(data.bar_profile_pic);
      setBarMenu(data.menu);
      setBarInsta(data.bar_insta);
      setTags(tagsData);
      setResultsAreLoading(false);
    },
    []
  );

  function handleUpdateBar(): void {
    const updateData: ReviewBarQuery = {
      bar_adress: barAddress,
      bar_insta: barInsta,
      bar_phone: barPhone,
      bar_profile_pic: barProfilePic,
      menu: barMenu,
      bar_id: BaseAPI.getSelectedBar().id,
      verified: true,
    };
    asyncCallback(
      isMounted,
      () => {
        return BarInfoAPI.updateBar(updateData);
      },
      (data) => {}
    );
  }

  const handleFileChange = (event: React.BaseSyntheticEvent) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (selectedFile) {
      asyncCallback(
        isMounted,
        () => {
          return BarInfoAPI.uploadNewPicture(
            selectedFile,
            BaseAPI.getSelectedBar().id
          );
        },
        (data) => {
          console.log(data);
          alert(data);
        }
      );
    }
  };

  const handleChange = (event: React.BaseSyntheticEvent) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/[^0-9]/g, "");

    if (numericValue.length > 11) {
      const truncatedValue = numericValue.slice(0, 11);
      setBarPhone(formatPhoneNumber(truncatedValue));
    } else {
      setBarPhone(formatPhoneNumber(numericValue));
    }
  };

  const formatPhoneNumber = (phoneNumber: string): string => {
    // Check if the phone number has 11 digits (including the area code)
    if (phoneNumber.length === 11) {
      // Format as (XX) XXXXX-XXXX
      return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(
        2,
        7
      )}-${phoneNumber.slice(7)}`;
    }
    // Check if the phone number has 10 digits (excluding the area code)
    else if (phoneNumber.length === 10) {
      // Format as (XX) XXXX-XXXX
      return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(
        2,
        6
      )}-${phoneNumber.slice(6)}`;
    }
    // Return the original value if it doesn't match the expected pattern
    else {
      return phoneNumber;
    }
  };

  return (
    <Container>
      {!resultsAreLoading ? (
        <>
          <div>
            <InputGrid>
              <StyledH3>Bairro: </StyledH3>
              <TextField
                value={barAddress}
                onChange={(e) => setBarAddress(e.target.value)}
              />

              <StyledH3>Instagram: </StyledH3>
              <TextField
                value={barInsta}
                onChange={(e) => setBarInsta(e.target.value)}
              />

              <StyledH3>Telefone: </StyledH3>
              <TextField value={barPhone} onChange={(e) => handleChange(e)} />

              <StyledH3>Foto de Perfil: </StyledH3>
              <GridItemWithLink>
                {/* <TextField
                value={barProfilePic}
                onChange={(e) => setBarProfilePic(e.target.value)}
              /> */}
                <input type="file" onChange={handleFileChange} />
                <IconButton onClick={handleUpload}>
                  <OpenInNew />
                </IconButton>
              </GridItemWithLink>

              <StyledH3>Cardápio: </StyledH3>
              <GridItemWithLink>
                <TextField
                  value={barMenu}
                  onChange={(e) => setBarMenu(e.target.value)}
                />
                <IconButton onClick={() => window.open(barMenu)}>
                  <OpenInNew />
                </IconButton>
              </GridItemWithLink>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Categoria 1
                </InputLabel>
                <Select>
                  {tags.map((entry) => (
                    <MenuItem value={entry.id}>{entry.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Categoria 2
                </InputLabel>
                <Select>
                  {tags.map((entry) => (
                    <MenuItem value={entry.id}>{entry.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Categoria 3
                </InputLabel>
                <Select>
                  {tags.map((entry) => (
                    <MenuItem value={entry.id}>{entry.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </InputGrid>

            <Button variant="outlined" onClick={handleUpdateBar}>
              Atualizar
            </Button>
          </div>
          <StyledImg
            src={`https://benhai.com.br/images/${
              BaseAPI.getSelectedBar().id
            }.jpg`}
            alt="Foto do bar"
          />
        </>
      ) : (
        <CircularProgress />
      )}
    </Container>
  );
}
