import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import { BaseAPI, LoginData } from "../../apis/BaseAPI";
import { useIsMounted } from "../../hooks/useIsMounted";
import { asyncCallback } from "../../utils";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

interface ErrorResponse {
  message: string;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  align-items: center;
`;

const LoginDiv = styled.div`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  -webkit-box-shadow: 7px 6px 16px 0px rgba(179, 168, 179, 0.6);
  -moz-box-shadow: 7px 6px 16px 0px rgba(179, 168, 179, 0.6);
  box-shadow: 7px 6px 16px 0px rgba(179, 168, 179, 0.6);
`;

export function LoginPage(): JSX.Element {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loginIsLoading, setLoginIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const isMounted = useIsMounted();

  const handleClickShowPassword = () =>
    setShowPassword((show: boolean) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  async function handleLogin(): Promise<void> {
    const loginData: LoginData = {
      email: email,
      password: password,
    };
    setLoginIsLoading(true);

    try {
      await asyncCallback(
        isMounted,
        () => {
          return BaseAPI.login(loginData);
        },
        () => {
          setLoginIsLoading(false);
        }
      );
      navigate("/bar-admin");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setLoginIsLoading(false);
        const axiosError = error as AxiosError<ErrorResponse>;
        console.error("Erro de login:", axiosError.response?.data.message);
        alert(`Erro de login:
        ${axiosError.response?.data.message}`);
      } else {
        setLoginIsLoading(false);
        console.error("An error occurred during login:", error);
      }
    }
  }

  return (
    <Container>
      <LoginDiv>
        {!loginIsLoading ? (
          <>
            <h1>Benhai</h1>
            <h2>Login de Administradores</h2>
            <FormControl sx={{ m: 1, width: "100%" }} variant="filled">
              <InputLabel>Email</InputLabel>
              <FilledInput onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl sx={{ m: 1, width: "100%" }} variant="filled">
              <InputLabel htmlFor="filled-adornment-password">Senha</InputLabel>
              <FilledInput
                id="filled-adornment-password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button
              variant="outlined"
              onClick={handleLogin}
              onKeyDown={(e) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                e.key === "Enter" ? handleLogin() : null;
              }}
            >
              Entrar
            </Button>
          </>
        ) : (
          <CircularProgress />
        )}
      </LoginDiv>
    </Container>
  );
}
