import { useState } from "react";
import { useAsync } from "../../../hooks/useAsync";
import { useIsMounted } from "../../../hooks/useIsMounted";
import styled from "styled-components";
import {
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  DeleteForever,
  OpenInNew,
  Unpublished,
  Verified,
} from "@mui/icons-material";
import { asyncCallback } from "../../../utils";
import { StyledH3 } from "../../../atoms/StyledTexts";
import {
  BarInfoAPI,
  CreateBarQuery,
  FetchBarList,
  ReviewBarQuery,
} from "../../../apis/BarApi";

const AdminConfigDiv = styled.div`
  display: flex;
  gap: 20px;
`;

const CategoriesDiv = styled.div`
  display: block;
  border-radius: 5px;
  -webkit-box-shadow: 7px 6px 16px 0px rgba(179, 168, 179, 0.6);
  -moz-box-shadow: 7px 6px 16px 0px rgba(179, 168, 179, 0.6);
  box-shadow: 7px 6px 16px 0px rgba(179, 168, 179, 0.6);
  padding: 20px;
  gap: 20px;
  justify-content: center;
  align-items: center;
  max-height: 70vh;
  overflow-y: auto;
`;

const FormDiv = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  border-radius: 5px;
  -webkit-box-shadow: 7px 6px 16px 0px rgba(179, 168, 179, 0.6);
  -moz-box-shadow: 7px 6px 16px 0px rgba(179, 168, 179, 0.6);
  box-shadow: 7px 6px 16px 0px rgba(179, 168, 179, 0.6);
  padding: 20px;
  gap: 20px;
  justify-content: center;
  align-items: center;
  max-height: 70vh;
  overflow-y: auto;
`;

const GridItemWithLink = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledTable = styled(Table)`
  overflow-y: auto;
  display: block;
`;

export function AdmBarConfig(): JSX.Element {
  const isMounted = useIsMounted();
  const [resultsAreLoading, setResultsAreLoading] = useState<boolean>(true);
  const [bars, setBars] = useState<FetchBarList>([]);

  const [barAddress, setBarAddress] = useState<string>("");
  const [barInsta, setBarInsta] = useState<string>("");
  const [barLat, setBarLat] = useState<string>("");
  const [barLong, setBarLong] = useState<string>("");
  const [barName, setBarName] = useState<string>("");

  const [barPhone, setBarPhone] = useState<string>("");
  const [barMenu, setBarMenu] = useState<string>("");

  useAsync(
    isMounted,
    async () => {
      handleFetchBars();
    },
    () => {},
    []
  );

  function handleDeleteBar(barId: number): void {
    asyncCallback(
      isMounted,
      () => {
        setResultsAreLoading(true);
        return BarInfoAPI.deleteBar(barId);
      },
      () => {
        handleFetchBars();
      }
    );
  }

  function handleFetchBars(): void {
    asyncCallback(
      isMounted,
      () => {
        setResultsAreLoading(true);
        return BarInfoAPI.fetchBarList();
      },
      (data) => {
        setBars(data);
        setResultsAreLoading(false);
      }
    );
  }

  function handleSubmit(): void {
    const newBarData: CreateBarQuery = {
      bar_adress: barAddress,
      bar_insta: barInsta,
      bar_latitude: barLat,
      bar_longitude: barLong,
      bar_name: barName,
      bar_phone: barPhone,
      menu: barMenu,
    };
    asyncCallback(
      isMounted,
      () => {
        setResultsAreLoading(true);
        return BarInfoAPI.createNewBar(newBarData);
      },
      () => {
        setBarAddress("");
        setBarInsta("");
        setBarLat("");
        setBarLong("");
        setBarMenu("");
        setBarName("");
        setBarPhone("");

        setResultsAreLoading(false);
        handleFetchBars();
      }
    );
  }

  function handleVerifyBar(barId: number, currentVerified: boolean): void {
    const verifyBarQuery: ReviewBarQuery = {
      bar_id: barId,
      verified: !currentVerified,
    };

    asyncCallback(
      isMounted,
      async () => {
        try {
          setResultsAreLoading(true);
          await BarInfoAPI.updateBar(verifyBarQuery);
        } catch (error) {
          console.error("An error occurred:", error);
        } finally {
          setResultsAreLoading(false);
          handleFetchBars();
        }
      },
      () => {}
    );
  }

  const handleChange = (event: React.BaseSyntheticEvent) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/[^0-9]/g, "");

    if (numericValue.length > 11) {
      const truncatedValue = numericValue.slice(0, 11);
      setBarPhone(formatPhoneNumber(truncatedValue));
    } else {
      setBarPhone(formatPhoneNumber(numericValue));
    }
  };

  const formatPhoneNumber = (phoneNumber: string): string => {
    // Check if the phone number has 11 digits (including the area code)
    if (phoneNumber.length === 11) {
      // Format as (XX) XXXXX-XXXX
      return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(
        2,
        7
      )}-${phoneNumber.slice(7)}`;
    }
    // Check if the phone number has 10 digits (excluding the area code)
    else if (phoneNumber.length === 10) {
      // Format as (XX) XXXX-XXXX
      return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(
        2,
        6
      )}-${phoneNumber.slice(6)}`;
    }
    // Return the original value if it doesn't match the expected pattern
    else {
      return phoneNumber;
    }
  };

  return (
    <>
      {resultsAreLoading ? (
        <CircularProgress />
      ) : (
        <AdminConfigDiv>
          <CategoriesDiv>
            <StyledH3>Bares</StyledH3>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Verificado</TableCell>
                  <TableCell>Deletar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bars?.map((entry) => (
                  <TableRow key={`tabelaBares${entry.id}${entry.bar_name}`}>
                    <TableCell>{entry.id}</TableCell>
                    <TableCell>{entry.bar_name}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          handleVerifyBar(entry.id, entry.verified)
                        }
                      >
                        {entry.verified ? <Verified /> : <Unpublished />}
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleDeleteBar(entry.id)}>
                        <DeleteForever />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </CategoriesDiv>
          <FormDiv>
            <div>
              <StyledH3>Nome: </StyledH3>
              <TextField
                value={barName}
                onChange={(e) => setBarName(e.target.value)}
              />
            </div>
            <div>
              <StyledH3>Instagram: </StyledH3>
              <TextField
                value={barInsta}
                onChange={(e) => setBarInsta(e.target.value)}
              />
            </div>
            <div>
              <StyledH3>Bairro: </StyledH3>
              <TextField
                value={barAddress}
                onChange={(e) => setBarAddress(e.target.value)}
              />
            </div>
            <div>
              <StyledH3>Telefone: </StyledH3>
              <TextField value={barPhone} onChange={(e) => handleChange(e)} />
            </div>
            <div>
              <StyledH3>Latitude: </StyledH3>
              <TextField
                value={barLat}
                onChange={(e) => setBarLat(e.target.value)}
              />
            </div>
            <div>
              <StyledH3>Longitude: </StyledH3>
              <TextField
                value={barLong}
                onChange={(e) => setBarLong(e.target.value)}
              />
            </div>
            <div>
              <StyledH3>Cardápio: </StyledH3>
              <GridItemWithLink>
                <TextField
                  value={barMenu}
                  onChange={(e) => setBarMenu(e.target.value)}
                />
                <IconButton onClick={() => window.open(barMenu)}>
                  <OpenInNew />
                </IconButton>
              </GridItemWithLink>
            </div>
            <Button variant="outlined" onClick={handleSubmit}>
              Adicionar
            </Button>
          </FormDiv>
        </AdminConfigDiv>
      )}
    </>
  );
}
