import { useState } from "react";
import { useAsync } from "../../../hooks/useAsync";
import { useIsMounted } from "../../../hooks/useIsMounted";
import styled from "styled-components";
import {
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import { asyncCallback } from "../../../utils";
import { CategoryAPI, FetchTags, TagsQuery } from "../../../apis/CategoryApi";
import { StyledH3 } from "../../../atoms/StyledTexts";

const AdminConfigDiv = styled.div`
  display: flex;
  gap: 20px;
`;

const CategoriesDiv = styled.div`
display: block;
border-radius: 5px;
-webkit-box-shadow: 7px 6px 16px 0px rgba(179, 168, 179, 0.6);
-moz-box-shadow: 7px 6px 16px 0px rgba(179, 168, 179, 0.6);
box-shadow: 7px 6px 16px 0px rgba(179, 168, 179, 0.6);
padding: 20px;
gap: 20px;
justify-content: center;
align-items: center;
max-height: 70vh;
overflow-y: auto;
`;

export function CatConfig(): JSX.Element {
  const isMounted = useIsMounted();
  const [availableCategories, setAvailableCategories] = useState<FetchTags>();
  const [resultsAreLoading, setResultsAreLoading] = useState<boolean>(true);
  const [tagName, setTagName] = useState<string>("");

  useAsync(
    isMounted,
    async () => {
      handleFetchCategories();
    },
    () => {},
    []
  );

  function handleDeleteCategory(tagId: number): void {
    asyncCallback(
      isMounted,
      () => {
        setResultsAreLoading(true);
        return CategoryAPI.deleteTag(tagId);
      },
      () => {
        handleFetchCategories();
      }
    );
  }

  function handleFetchCategories(): void {
    asyncCallback(
      isMounted,
      () => {
        setResultsAreLoading(true);
        return CategoryAPI.fetchTags();
      },
      (data) => {
        setAvailableCategories(data);
        setResultsAreLoading(false);
      }
    );
  }

  function handleSubmit(): void {
    const newTagData: TagsQuery = {
      name: tagName,
    };
    asyncCallback(
      isMounted,
      () => {
        setResultsAreLoading(true);
        return CategoryAPI.addTag(newTagData);
      },
      () => {
        setTagName("");
        setResultsAreLoading(false);
        handleFetchCategories();
      }
    );
  }

  return (
    <>
      {resultsAreLoading ? (
        <CircularProgress />
      ) : (
        <AdminConfigDiv>
          <CategoriesDiv>
            <StyledH3>Categorias</StyledH3>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Deletar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {availableCategories?.map((entry) => (
                  <TableRow key={`tabelaPromo${entry.id}${entry.name}`}>
                    <TableCell>{entry.id}</TableCell>
                    <TableCell>{entry.name}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => handleDeleteCategory(entry.id)}
                      >
                        <DeleteForever />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CategoriesDiv>
          <CategoriesDiv>
            <TextField
              label={"Categoria"}
              onChange={(e) => setTagName(e.target.value)}
            />
            <Button variant="outlined" onClick={handleSubmit}>
              Adicionar
            </Button>
          </CategoriesDiv>
        </AdminConfigDiv>
      )}
    </>
  );
}
