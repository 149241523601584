import "./App.css";
import { BaseAPI } from "./apis/BaseAPI";
import { BarAdminPage } from "./pages/BarAdmin/BarAdminPage";
import { LoginPage } from "./pages/Login/LoginPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            BaseAPI.getIsInitialized() ? <BarAdminPage /> : <LoginPage />
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/bar-admin" element={<BarAdminPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
