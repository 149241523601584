import { StyledH1 } from "../../../atoms/StyledTexts";
import { Reviews } from "../../../components/Reviews";

export function ReviewsAdmin(): JSX.Element {
  return (
    <div>
      <StyledH1>Reviews de Clientes</StyledH1>
      <Reviews />
    </div>
  );
}
