import { useRef } from "react";

export function randomNumber(max: number, min = 0): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export async function wait(ms: number): Promise<void> {
  await new Promise((resolve) => setTimeout(resolve, ms));
}

export function computePageCount(
  numberOfResults: number,
  resultsPerPage: number,
): number {
  return Math.max(1, Math.ceil(numberOfResults / resultsPerPage));
}

export function capitalize(string: string): string {
  return `${string[0].toUpperCase()}${string.slice(1)}`;
}

export async function asyncCallback<T>(
  isMounted: ReturnType<typeof useRef>,
  asyncPart: () => Promise<T>,
  syncPart: (value: T) => void,
  setIsLoading?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | Array<React.Dispatch<React.SetStateAction<boolean>>>
): Promise<void> {
  if (Array.isArray(setIsLoading)) {
    setIsLoading.forEach((setter) => setter(true));
  } else if (setIsLoading) {
    setIsLoading(true);
  }

  const result = await asyncPart();

  if (isMounted.current) {
    if (result) {
      syncPart && syncPart(result);
    } else {
      syncPart && syncPart(result as T);
    }

    if (Array.isArray(setIsLoading)) {
      setIsLoading.forEach((setter) => setter(false));
    } else if (setIsLoading) {
      setIsLoading(false);
    }
  }
}

export type UseStateSetterType<T> = React.Dispatch<React.SetStateAction<T>>;

export function cypressClassName(className: string): string {
  if (process.env.TEST_ENVIRONMENT === "true") {
    return `cypress${className}`;
  }

  return "";
}

export type NullableProperties<Type, Keys extends keyof Type> = Omit<
  Type,
  Keys
> &
  {
    [Key in Keys]: Type[Key] | null;
  };

export type NonNullableProperties<Type, Keys extends keyof Type> = Omit<
  Type,
  Keys
> &
  {
    [Key in Keys]: NonNullable<Type[Key]>;
  };

export const EmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export type DeepPartial<Type> = [Type] extends [object]
  ? {
      [Key in keyof Type]?: DeepPartial<Type[Key]>;
    }
  : Type;

export type A = DeepPartial<{
  a: {
    b: number;
  };
}>;
