import styled from "styled-components";
import PageDisplayWithTabs from "../../components/PageDisplayWithTabs";
import { BaseAPI } from "../../apis/BaseAPI";
import { useAsync } from "../../hooks/useAsync";
import { useState } from "react";
import { BarInfoAPI, FetchBarList } from "../../apis/BarApi";
import { useIsMounted } from "../../hooks/useIsMounted";
import { CircularProgress } from "@mui/material";
import { asyncCallback } from "../../utils";

const BarAdminPageDiv = styled.div`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export function BarAdminPage(): JSX.Element {
  const barData = BaseAPI.getSelectedBar();
  const [resultsAreLoading, setResultsAreLoading] = useState<boolean>(true);
  const [barList, setBarList] = useState<FetchBarList | undefined>([]);
  const isMounted = useIsMounted();

  useAsync(
    isMounted,
    async () => {
      if (BaseAPI.getUser().user.manager_account) {
        return BarInfoAPI.fetchBarList();
      }
    },
    (data) => {
      setBarList(data);
      setResultsAreLoading(false);
    },
    []
  );

  function handleBarChanged(barId: number): void {
    asyncCallback(
      isMounted,
      () => {
        setResultsAreLoading(true);
        return BaseAPI.setSelectedBar(barId);
      },
      () => {
        setResultsAreLoading(false);
      }
    );
  }

  return (
    <>
      {resultsAreLoading ? (
        <CircularProgress />
      ) : (
        <BarAdminPageDiv>
          {BaseAPI.getUser().user.manager_account ? (
            <>
              <h1>Selecione um bar</h1>
              <select
                onChange={(e) => handleBarChanged(Number(e.target.value))}
              >
                <option>Selecionar um bar</option>
                {barList?.map((entry) => (
                  <option
                    value={entry.id}
                    key={`barSelectionKey${entry.bar_name}`}
                  >
                    {entry.bar_name}
                  </option>
                ))}
              </select>
            </>
          ) : null}
          {barData ? (
            <>
              <h1>{`Bem vindo, ${barData.bar_name}!`}</h1>
              <PageDisplayWithTabs />
            </>
          ) : null}
        </BarAdminPageDiv>
      )}
    </>
  );
}
