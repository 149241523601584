import { useState } from "react";
import { BarInfoAPI, FetchReviews } from "../apis/BarApi";
import { useAsync } from "../hooks/useAsync";
import { useIsMounted } from "../hooks/useIsMounted";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  CircularProgress,
} from "@mui/material";
import { BaseAPI } from "../apis/BaseAPI";

export function Reviews(): JSX.Element {
  const [resultsAreLoading, setResultsAreLoading] = useState<boolean>(true);
  const [data, setData] = useState<FetchReviews>([]);
  const isMounted = useIsMounted();
  const sum = data ? data.reduce((a, b) => a + b.stars, 0) : 0;
  const avg = sum / data.length || 0;

  useAsync(
    isMounted,
    async () => {
      return BarInfoAPI.fetchBarReviews(BaseAPI.getSelectedBar().id);
    },
    (data) => {
      setData(data.reviews);
      setResultsAreLoading(false);
    },
    []
  );

  return (
    <>
      {!resultsAreLoading ? (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Autor</TableCell>
                <TableCell>Data</TableCell>
                <TableCell align="right">Review</TableCell>
                <TableCell align="right">Nota</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {data.map((entry) => (
                  <TableRow>
                    <TableCell>{entry.author}</TableCell>
                    <TableCell>{entry.date}</TableCell>
                    <TableCell align="right">{entry.review_text}</TableCell>
                    <TableCell align="right">{entry.stars}</TableCell>
                  </TableRow>
                ))}
              </>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>{""}</TableCell>
                <TableCell>{""}</TableCell>
                <TableCell>{""}</TableCell>

                <TableCell>Média: {avg}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}
