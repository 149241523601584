import { useEffect, useRef } from "react";

export function useIsMounted(): ReturnType<typeof useRef> {
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
}
