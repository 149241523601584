import { BaseAPI } from "./BaseAPI";

export interface Tags {
  id: number;
  name: string;
}

export type TagsQuery = Omit<Tags, "id">;

export type FetchTags = Array<Tags>;

export class CategoryAPI {
  public static tagsPath = `/tags`;

  public static deleteTagsPath = (id: number) => `/tags/delete/${id}`;

  public static async fetchTags(): Promise<FetchTags> {
    const baseClient = BaseAPI.getClient();
    const response = await baseClient.request({
      url: CategoryAPI.tagsPath,
    });

    return response.data;
  }

  public static async addTag(data: TagsQuery): Promise<void> {
    const baseClient = BaseAPI.getClient();
    await baseClient.request({
      url: CategoryAPI.tagsPath,
      method: "POST",
      data: data,
    });
  }

  public static async deleteTag(id: number): Promise<void> {
    const baseClient = BaseAPI.getClient();
    await baseClient.request({
      url: CategoryAPI.deleteTagsPath(id),
      method: "DELETE",
    });
  }
}
