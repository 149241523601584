import {
  AccordionSummary,
  Typography,
  AccordionDetails,
  Accordion,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { groupBy } from "lodash";
import { Delete, ExpandMore } from "@mui/icons-material";
import styled from "@emotion/styled";
import { BarInfoAPI, FetchBarPromos } from "../apis/BarApi";
import { handleDays } from "../constants/Days";
import { useState } from "react";
import { BaseAPI } from "../apis/BaseAPI";
import { useAsync } from "../hooks/useAsync";
import { useIsMounted } from "../hooks/useIsMounted";
import { asyncCallback } from "../utils";

interface PromosAccordionProps {
  refreshTable: boolean;
}

export const ColloredExpandMore = styled(ExpandMore)``;

const PromosAccordionDiv = styled(Accordion)`
  border-bottom: 1px solid;
  border-radius: 0px;
`;

const DetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 10px;
  border-bottom: 1px solid gray;
`;

const DetailsList = styled.div`
  display: list-item;
  list-style: auto;
  margin-left: 20px;
`;

const PrimaryText = styled.div`
  font-size: 0.875rem;
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-between;
`;

const SecondaryText = styled.span`
  font-size: 0.875rem;
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-between;
  align-items: center;
`;

export function PromosAccordion(props: PromosAccordionProps): JSX.Element {
  const { refreshTable } = props;
  const [resultsAreLoading, setResultsAreLoading] = useState<boolean>();
  const [data, setData] = useState<FetchBarPromos>([]);
  const isMounted = useIsMounted();

  useAsync(
    isMounted,
    async () => {
      handleFetchPromos();
    },
    () => {},
    [refreshTable]
  );

  function handleDeletePromo(promoId: number): void {
    asyncCallback(
      isMounted,
      () => {
        setResultsAreLoading(true);
        return BarInfoAPI.deletePromo(promoId);
      },
      () => {
        handleFetchPromos();
      }
    );
  }

  function handleFetchPromos(): void {
    asyncCallback(
      isMounted,
      () => {
        setResultsAreLoading(true);
        return BarInfoAPI.fetchBarPromos(BaseAPI.getSelectedBar().id);
      },
      (data) => {
        setData(data);
        setResultsAreLoading(false);
      }
    );
  }
  let dataGroupedByDay = groupBy(data, "promo_day");

  return (
    <>
      {!resultsAreLoading ? (
        Object.keys(dataGroupedByDay).map((promo, index) => {
          const group = dataGroupedByDay[promo];
          return (
            <PromosAccordionDiv
              key={
                `promoDiv` +
                data[index].promo_day +
                data[index].promo_category +
                data[index].promo_value
              }
            >
              <AccordionSummary expandIcon={<ColloredExpandMore />}>
                <Typography variant="body2">
                  {handleDays(group[0].promo_day)}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                {group.map((x, index) => (
                  <DetailsList key={x.id + x.promo_category + index}>
                    <DetailsDiv>
                      <PrimaryText>
                        <div>{`${x.promo_item}`}</div>
                        <div>{`R$ ${x.promo_value}`}</div>
                      </PrimaryText>
                      <SecondaryText>
                        <div>{`${x.category.name} - ${x.promo_time}`}</div>
                        <IconButton onClick={() => handleDeletePromo(x.id)}>
                          <Delete />
                        </IconButton>
                      </SecondaryText>
                    </DetailsDiv>
                  </DetailsList>
                ))}
              </AccordionDetails>
            </PromosAccordionDiv>
          );
        })
      ) : (
        <CircularProgress />
      )}
    </>
  );
}
