import { StyledH1 } from "../atoms/StyledTexts";
import { PromosAccordion } from "./PromosAccordion";

interface PromosTableProps {
  refreshTable: boolean;
  setRefreshTable: (event: boolean) => void;

}

export default function PromosTable(props: PromosTableProps) {
  const { refreshTable } = props;

  return (
    <div>
      <StyledH1>Promocoes cadastradas</StyledH1>

      <PromosAccordion refreshTable={refreshTable} />
    </div>
  );
}
