import { StyledH1 } from "../../../atoms/StyledTexts";
import { BarConfig } from "../../../components/BarConfig";

export function BarConfigAdmin(): JSX.Element {
  return (
    <div>
      <StyledH1>Configurações do Bar</StyledH1>
      <BarConfig />
    </div>
  );
}
