import styled from "styled-components";
import { NewPromoForm } from "../../../components/NewPromoForm";
import PromosTable from "../../../components/PromosTable";
import { useState } from "react";

const PromosAdminDiv = styled.div`
  display: flex;
  gap: 50px;
`;

export function PromosAdmin(): JSX.Element {
  const [refreshTable, setRefreshTable] = useState(false);

  return (
    <>
      <PromosAdminDiv>
        <PromosTable
          refreshTable={refreshTable}
          setRefreshTable={setRefreshTable}
        />
        <NewPromoForm
          setRefreshTable={setRefreshTable}
          refreshTable={refreshTable}
        />
      </PromosAdminDiv>
    </>
  );
}
