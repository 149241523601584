import { DependencyList, useEffect, useRef } from "react";
import { asyncCallback } from "../utils";

export function useAsync<T>(
  isMounted: ReturnType<typeof useRef>,
  asyncPart: () => Promise<T>,
  syncPart: (value: T) => void,
  deps?: DependencyList,
  setIsLoading?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | Array<React.Dispatch<React.SetStateAction<boolean>>>
): void {
  useEffect(() => {
    asyncCallback(isMounted, asyncPart, syncPart, setIsLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
