import { AxiosResponse, CancelToken } from "axios";
import { BaseAPI } from "./BaseAPI";

export interface Bar {
  id: number;
  bar_name: string;
  bar_insta: string;
  bar_adress: string;
  bar_phone: string;
  tags: string;
}

export interface BarPromo {
  id: number;
  promo_category: string;
  promo_item: string;
  promo_value: string;
  promo_description: string;
  promo_time: string;
  promo_day: number;
  fk_bar_id: number;
  category: {
    id: number;
    name: string;
  };
}

export interface BarInfo {
  id: number;
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
  bar_name: string;
  bar_adress: string;
  bar_insta: string;
  bar_phone: string;
  bar_profile_pic: string;
  bar_longitude: string;
  bar_latitude: string;
  menu: string;
  verified: number;
  tag1: number;
  tag2: number | null;
  tag3: number | null;
}

export type BarNameForDropdown = {
  bar_name: string;
};

export interface CreateBarQuery {
  bar_name?: string;
  bar_insta?: string;
  bar_adress?: string;
  bar_phone?: string;
  bar_longitude?: string;
  bar_latitude?: string;
  menu?: string;
  bar_profile_pic?: string;
  tag1?: string;
  tag2?: string;
  tag3?: string;
}

export type BarQuery = Omit<CreateBarQuery, "tag1" | "tag2" | "tag3"> & {
  tag: string;
};

export interface ReviewBarQuery extends CreateBarQuery {
  bar_id: number;
  verified: boolean;
}

export interface PromoQuery {
  promo_category: string;
  promo_item: string;
  promo_value: string;
  promo_description: string;
  promo_time: string;
  promo_day: number;
  fk_bar_id: number;
}

export interface Review {
  id: number;
  date: string;
  review_text: string;
  stars: number;
  author: string;
}

export interface ReviewQuery {
  fk_bar_id: number;
  review_text: string;
  stars: number;
  author: string;
}

export interface BarList {
  bar_name: string;
  id: number;
  verified: boolean;
}

export type FetchBars = Array<Bar>;

export type FetchBarPromos = Array<BarPromo>;

export type FetchBarList = Array<BarList>;

export type NewPromoData = BarPromo;

export type FetchReviews = Array<Review>;

export class BarInfoAPI {
  public static today = new Date().getDay();

  public static barInfoPath = (barId: number): string => `/bars/${barId}`;

  public static addBarPath = `/bars`;

  public static deleteBarPath = (barId: number): string =>
    `/bars/delete/${barId}`;

  public static promoInfoPath = "/promos";

  public static deletePromoPath = (promoId: number): string =>
    `/promos/delete/${promoId}`;

  public static barPromoPath = (barId: number): string => `/promos/${barId}`;

  public static barReviewPath = `/reviews`;

  public static barsToReviewPath = `/bars/review`;

  public static barListPath = `/bars/simple`;

  public static barsToReviewPathSend = `/bars/review/send`;

  public static uploadImagePath = "/image/upload";

  public static async fetchBarInfo(
    barId: number,
    cancelToken?: CancelToken
  ): Promise<BarInfo> {
    const baseClient = BaseAPI.getClient();
    const response = await baseClient.request({
      url: BarInfoAPI.barInfoPath(barId),
      params: cancelToken,
    });

    return response.data;
  }

  public static async fetchBarList(
    cancelToken?: CancelToken
  ): Promise<FetchBarList> {
    const baseClient = BaseAPI.getClient();
    const response = await baseClient.request({
      url: BarInfoAPI.barListPath,
      params: cancelToken,
    });

    return response.data;
  }

  public static async fetchBarPromos(
    barId: number,
    cancelToken?: CancelToken
  ): Promise<FetchBarPromos> {
    const baseClient = BaseAPI.getClient();
    const response = await baseClient.request({
      url: BarInfoAPI.barPromoPath(barId),
      params: cancelToken,
    });

    return response.data;
  }

  public static async updateBar(
    bar: ReviewBarQuery,
    cancelToken?: CancelToken
  ): Promise<number> {
    const baseClient = BaseAPI.getClient();
    const response = await baseClient.request({
      url: BarInfoAPI.barsToReviewPathSend,
      data: bar,
      method: "PUT",
      cancelToken,
    });

    return response.status;
  }

  public static async createNewBar(
    bar: CreateBarQuery,
    cancelToken?: CancelToken
  ): Promise<void> {
    const baseClient = BaseAPI.getClient();
    const response = await baseClient.request({
      url: BarInfoAPI.addBarPath,
      data: bar,
      method: "POST",
      cancelToken,
    });

    return response.data;
  }

  public static async deleteBar(
    id: number,
    cancelToken?: CancelToken
  ): Promise<void> {
    const baseClient = BaseAPI.getClient();
    const response = await baseClient.request({
      url: BarInfoAPI.deleteBarPath(id),
      method: "DELETE",
      cancelToken,
    });

    return response.data;
  }

  public static async createNewPromo(
    promo: PromoQuery,
    cancelToken?: CancelToken
  ): Promise<void> {
    const baseClient = BaseAPI.getClient();
    await baseClient.request({
      url: BarInfoAPI.promoInfoPath,
      data: promo,
      method: "POST",
      cancelToken,
    });
  }

  public static async deletePromo(
    promoId: number,
    cancelToken?: CancelToken
  ): Promise<void> {
    const baseClient = BaseAPI.getClient();
    await baseClient.request({
      url: BarInfoAPI.deletePromoPath(promoId),
      method: "DELETE",
      cancelToken,
    });
  }

  public static async fetchBarReviews(
    barId: number,
    cancelToken?: CancelToken
  ): Promise<{ reviews: FetchReviews }> {
    const baseClient = BaseAPI.getClient();
    const response = await baseClient.request({
      url: BarInfoAPI.barReviewPath,
      params: { fk_bar_id: barId },
    });

    return response.data;
  }

  public static async uploadNewPicture(
    file: File,
    bar_id: number,
    cancelToken?: CancelToken
  ): Promise<AxiosResponse> {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("bar_id", String(bar_id));

    const baseClient = BaseAPI.getClient();
    const response = await baseClient.request({
      url: BarInfoAPI.uploadImagePath,
      data: formData,
      method: "POST",
      cancelToken,
    });

    return response.data.message;
  }
}
