import axios, { AxiosInstance } from "axios";
import { BarInfoAPI } from "./BarApi";

export interface LoginData {
  email: string;
  password: string;
}

export interface Bar {
  id: number;
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
  bar_name: string;
  bar_adress: string;
  bar_insta: string;
  bar_phone: string | null;
  bar_profile_pic: string;
  bar_longitude: string;
  bar_latitude: string;
  menu: string;
  verified: number;
  tag1: number;
  tag2: number | null;
  tag3: number | null;
}

export interface UserI {
  user: {
    id: number;
    email: string;
    last_login: string;
    fk_bar_id: number;
    manager_account: boolean;
  };

  bar?: Bar;
}



export class BaseAPI {
  public static loginPath = "/login";
  public static baseUrl = "https://benhai.com.br/api/";

  private static client: AxiosInstance;

  private static isInitialized = false;

  private static user: UserI;

  private static selectedBar: Bar;

  public static async login(loginData: LoginData): Promise<void> {
    const response = await axios.request({
      url: `${this.baseUrl}login`,
      params: loginData,
      method: "POST",
    });

    BaseAPI.client = axios.create({
      baseURL: this.baseUrl,
      headers: {},
    });

    BaseAPI.isInitialized = true;
    if (response.data.user.manager_account) {
      BaseAPI.user = response.data;
    } else {
      console.log(response.data);
      BaseAPI.user = response.data;
      BaseAPI.selectedBar = response.data.bar;
    }
  }

  public static checkIsInitialized(): void {
    if (!BaseAPI.isInitialized) {
      window.location.href = "/login";
      throw new Error("Faça login antes de usar a aplicação");
    }
  }

  public static getClient(): AxiosInstance {
    this.checkIsInitialized();
    return this.client;
  }

  public static getUser(): UserI {
    this.checkIsInitialized();
    return this.user;
  }

  public static getIsInitialized(): boolean {
    return this.isInitialized;
  }

  public static getSelectedBar(): Bar {
    return this.selectedBar;
  }

  public static async setSelectedBar(id: number): Promise<void> {
     const response = await BarInfoAPI.fetchBarInfo(id)
    this.selectedBar = response;
  }
}
