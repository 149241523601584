export const days = {
    0: "Domingo",
    1: "Segunda",
    2: "Terça",
    3: "Quarta",
    4: "Quinta",
    5: "Sexta",
    6: "Sábado"
}

export function handleDays(day: number): string {
    switch (day) {
        case 0:
            return days[0]
        case 1:
            return days[1]
        case 2:
            return days[2]
        case 3:
            return days[3]
        case 4:
            return days[4]
        case 5:
            return days[5]
        case 6:
            return days[6]
    }
    return "";
}