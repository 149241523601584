import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import { StyledH1 } from "../atoms/StyledTexts";
import { useState } from "react";
import { BarInfoAPI, PromoQuery } from "../apis/BarApi";
import { useAsync } from "../hooks/useAsync";
import { useIsMounted } from "../hooks/useIsMounted";
import { asyncCallback } from "../utils";
import { BaseAPI } from "../apis/BaseAPI";
import { CategoryAPI, FetchTags } from "../apis/CategoryApi";

const NewPromoFormDiv = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  -webkit-box-shadow: 7px 6px 16px 0px rgba(179, 168, 179, 0.6);
  -moz-box-shadow: 7px 6px 16px 0px rgba(179, 168, 179, 0.6);
  box-shadow: 7px 6px 16px 0px rgba(179, 168, 179, 0.6);
  padding: 20px;
  gap: 20px;
`;

const InnerDiv = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  gap: 20px;
`;

interface NewPromoFormProps {
  refreshTable: boolean;

  setRefreshTable: (event: boolean) => void;
}

export function NewPromoForm(props: NewPromoFormProps): JSX.Element {
  const { setRefreshTable, refreshTable } = props;
  const [availableCategories, setAvailableCategories] = useState<FetchTags>();

  const [day, setDay] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [item, setItem] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [time, setTime] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const [resultsAreLoading, setResultsAreLoading] = useState<boolean>(true);
  const isMounted = useIsMounted();
  const regex = /[^\d,.-]+/g;

  useAsync(
    isMounted,
    async () => {
      return CategoryAPI.fetchTags();
    },
    (data) => {
      setAvailableCategories(data);
      setResultsAreLoading(false);
    },
    []
  );

  const handleDayChange = (event: SelectChangeEvent) => {
    setDay(event.target.value as string);
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  function handleSubmit(): void {
    const newPromoData: PromoQuery = {
      fk_bar_id: BaseAPI.getSelectedBar().id,
      promo_day: Number(day),
      promo_description: description,
      promo_item: item,
      promo_category: category,
      promo_value: price.replace(regex, ""),
      promo_time: time,
    };
    asyncCallback(
      isMounted,
      () => {
        setResultsAreLoading(true);
        return BarInfoAPI.createNewPromo(newPromoData);
      },
      () => {
        setResultsAreLoading(false);
        setRefreshTable(!refreshTable);
      }
    );
  }

  const handleChange = (event: React.BaseSyntheticEvent) => {
    const inputValue = event.target.value;

    // Remove non-numeric characters
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    // Format the numeric value as currency
    const formattedValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(parseInt(numericValue) / 100); // Divide by 100 to handle cents

    setPrice(formattedValue);
  };

  return (
    <div>
      <StyledH1>Nova Promoção</StyledH1>
      <NewPromoFormDiv>
        <InnerDiv>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Dia da Semana</InputLabel>
            <Select
              value={day}
              label="Dia da Semana"
              onChange={handleDayChange}
            >
              <MenuItem value={0}>Domingo</MenuItem>
              <MenuItem value={1}>Segunda</MenuItem>
              <MenuItem value={2}>Terça</MenuItem>
              <MenuItem value={3}>Quarta</MenuItem>
              <MenuItem value={4}>Quinta</MenuItem>
              <MenuItem value={5}>Sexta</MenuItem>
              <MenuItem value={6}>Sábado</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
            <Select
              value={category}
              label="Categoria"
              onChange={handleCategoryChange}
              disabled={resultsAreLoading}
            >
              {availableCategories
                ? availableCategories.map((entry) => (
                    <MenuItem value={entry.id}>{entry.name}</MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
          <TextField label={"Item"} onChange={(e) => setItem(e.target.value)} />
          <TextField
            label={"Preço"}
            InputProps={{
              startAdornment: <span>R$ </span>,
            }}
            onChange={(e) => handleChange(e)}
          />
          <TextField
            label={"Horário"}
            onChange={(e) => setTime(e.target.value)}
          />
        </InnerDiv>
        <TextField
          label={"Observação"}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Button variant="outlined" onClick={handleSubmit}>
          Adicionar
        </Button>
      </NewPromoFormDiv>
    </div>
  );
}
